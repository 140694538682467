.middle {
    width: 400px;
    margin: 250px auto 10px auto;
    min-height: 300px;
}

.middle h1 {
    font-size: 30px;
    color: #444444;
    text-align: center;
}