/* ----- AUTHENTICATIONS ----- */
.form-back {
  float: left;
  width: 100%;
  height: 100vh;
  align-items: center;
  background: linear-gradient(rgba(16, 29, 45, 0.9), rgba(16, 29, 45, 0.7)), url("./images/office.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: rgba(68, 68, 68, 0.5);
}

.form-back .window {
  width: 100%;
  max-width: 330px;
  padding: 15px 30px;
  min-height: 150px;
  border-radius: 25px;
  margin: auto;
  margin-top: 200px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media only screen and (max-width: 600px) {
  .form-back .window {
    padding: 20px 30px;
    width: calc(100% - 60px);
    height: calc(100vh - 40px);
    margin: 0px;
    border-radius: 0px;
    background-color: #ffffff;
  }
}

/* ----- FORMS -----  */
.form {
  width: 100%;
  min-height: 150px;
}

.form h1, .form h2 {
  width: 100%;
  text-align: start;
  color: #444444;
  font-size: 40px;
  margin-top: 0px;
}
.form h2 {
  font-size: 25px;
}

.form .input-container {
  width: 330px;
  position: relative;
  margin-bottom: 30px;
}

.form input[type="text"],
.form input[type="password"],
.form input[type="number"] {
  width: 300px;
  padding: 15px;
  border: none;
  border-bottom: 1px solid #444444;
  font-size: 16px;
  background-color: #f5f5f5;
  transition: border-bottom-color 0.3s, box-shadow 0.3s;
}

.form input[type="text"]:focus,
.form input[type="password"]:focus,
.form input[type="number"]:focus {
  outline: none;
  border-bottom-color: #ff7f50;
}

.form label {
  position: absolute;
  top: 10px;
  left: 15px;
  color: #999;
  font-size: 16px;
  pointer-events: none;
  transition: top 0.3s, font-size 0.3s, color 0.3s;
}

.form input[type="text"]:focus + label,
.form input[type="text"]:valid + label,
.form input[type="text"]:-webkit-autofill + label,
.form input[type="password"]:focus + label,
.form input[type="password"]:valid + label,
.form input[type="password"]:-webkit-autofill + label,
.form input[type="number"]:focus + label,
.form input[type="number"]:valid + label,
.form input[type="number"]:-webkit-autofill + label {
  top: -20px;
  font-size: 12px;
  color: #444444;
}

.form input::-webkit-outer-spin-button,
.form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form .textarea-container {
  width: 300px;
  position: relative;
  margin-bottom: 20px;
}

.form .toggle-visibility {
  position: absolute;
  float: right;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #444444;
  transition: color 0.3s;
}

.form textarea {
  width: 300px;
  height: 150px; /* Adjust height as needed */
  padding: 15px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  font-size: 16px;
  background-color: #f5f5f5;
  resize: none; /* Prevent resizing */
  transition: border-color 0.3s, box-shadow 0.3s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.form textarea:focus {
  outline: none;
  border-color: #ff7f50;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form label {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #999;
  font-size: 16px;
  pointer-events: none;
  transition: top 0.3s, font-size 0.3s, color 0.3s;
}

.form textarea:focus + label,
.form textarea:valid + label,
.form textarea:-webkit-autofill + label {
  top: -20px;
  font-size: 12px;
  color: #444444;
}

.form span {
  color: #444444;
  cursor: pointer;
}

.form span:hover {
  border-bottom: 1px solid #444444;
}

.form button {
  font-size: 15px;
  padding: 8px 25px;
  border: transparent;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
  background: #2174ea;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.form button:hover {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(33,116,234,1) 0%, rgba(0,212,255,1) 100%);
}
 
.form button:active {
  transform: translate(0em, 0.2em);
}

.form p {
  width: 100%;
  color: #64748b;
}

.form .tags {
  float: left;
  width: 100%;
  min-height: 5px;
  margin-bottom: 20px;
}

.form .tags .tag,
.form .tags .active-tag {
  float: left;
  padding: 2px 7px;
  margin: 5px;
  border: 1px solid #555555;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.form .tags .active-tag {
  background-color: #555555;
  color: #ffffff;
  font-weight: 500;
}

.form .tags .tag:hover,
.form .tags .active-tag:hover {
  border: 1px solid #555555;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



















.copy-button {
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  border: none;
  font-size: 16px;
  background-color: inherit;
  border-radius: 10px;
  font-weight: 600;
  color: #2174ea;
  box-shadow: 0 0 0 2px #2174ea;
  cursor: pointer;
  overflow: hidden;
  margin: 10px 0px 0px 30px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.copy-button span:last-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #2174ea;
  border-radius: 10%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.320, 1);
}

.copy-button span:first-child {
  position: relative;
  z-index: 1;
}

.copy-button:hover {
  box-shadow: 0 0 0 5px #2174ea60;
  color: #ffffff;
}

.copy-button:active {
  scale: 0.95;
}

.copy-button:hover span:last-child {
  width: 100%;
  height: 150px;
  opacity: 1;
}


