.navbar {
    max-width: calc(1400px - 10%);
    margin: auto;
    padding: 10px 5%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -70px;
}
  
.navbar span {
    float: left;
    color: #94a3b8;
    font-size: 17px;
    cursor: pointer;
    font-weight: 500;
    margin: 0 10px;
    text-decoration: none;
}
  
.navbar span:hover {
    color: #2174ea;
}
  
.navbar .menu {
    position: fixed;
    top:70px;
    right:0px;
    bottom:0px;
    left:0px;
    z-index: 2;
    background-color: #ffffff
}
  
.navbar .menu .pages {
    width: 90%;
    margin: 0 5%;
    padding: 10px 0;
    border-bottom: 1px solid #64748b;
    float: left;
}
  
.navbar .menu .pages span {
    width: 100%;
    margin: 7px 0;
    color: #111111;
    text-decoration: none;
}

.navbar .hamburger {
    cursor: pointer;
}

.navbar .hamburger input {
    display: none;
}

.navbar .hamburger svg {
    /* The size of the SVG defines the overall size */
    height: 3em;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.navbar .line {
    fill: none;
    stroke: #94a3b8;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.navbar .line-top-bottom {
    stroke-dasharray: 12 63;
}

.navbar .hamburger input:checked + svg {
    transform: rotate(-45deg);
}

.navbar .hamburger input:checked + svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
}